/**======================================================================
=========================================================================
Template Name: Able Pro Admin Template
Author: Phoenixcoded
supports: https://phoenixcoded.ticksy.com
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */


// Theme Font
@import "themes/font/theme-font";

// theme scrollbar
@import "themes/plugin/perfect-scrollbar";

// main framework
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "settings/custom-variables";
@import "settings/theme-variables";

// ng module
@import "react-module/react-module";

// bootstrap
@import "~bootstrap/scss/bootstrap";


// icon
@import "themes/font/next-icon";
@import "themes/font/feather";
@import "themes/font/fontawesome";
@import "themes/font/flaticon";
@import "themes/font/cryptofont";

// bootstrap css
@import "themes/bootstrap-overlay/card";
@import "themes/bootstrap-overlay/button";
@import "themes/bootstrap-overlay/radiobox-checkbox";
@import "themes/bootstrap-overlay/switches";
@import "themes/bootstrap-overlay/badge";

@import "themes/general";
@import "themes/generic";
@import "themes/plugin/animate";


// theme layouts
@import "themes/layouts/menu-lite";
@import "themes/layouts/menu-react";
@import "layout-dark";
@import "layout-rtl";

// Dashboard Widgets
@import "themes/dashboard/widget";

// custom plugin
@import "themes/plugin/sweatalert";
@import "themes/plugin/lightbox";
@import "themes/plugin/notification";
@import "themes/plugin/pnotify";
@import "themes/plugin/bar-rating";
@import "themes/plugin/rangeslider";
@import "themes/plugin/daterange";
@import "themes/plugin/tags-input";
@import "themes/plugin/maxlength";
@import "themes/plugin/wizard";
@import "themes/plugin/select2";
@import "themes/plugin/data-tables";

// Charts
@import "themes/plugin/highcharts";
@import "themes/plugin/peitychart";


// pages
@import "themes/pages/authentication";
@import "themes/pages/maintaince";
@import "themes/pages/pages";
@import "themes/pages/help-desk";
@import "themes/pages/fullcalender";
@import "themes/pages/icon-lauouts";
@import "themes/pages/user";
@import "themes/pages/task-board";
@import "themes/pages/masonry-gallery";

// custom
@import "themes/custom";

.dropdown-toggle::after {
  display: none !important
}

.modal-sm {
  max-width: 600px !important
}

#button-tooltip>.tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
}

#button-tooltip>.tooltip-arrow {
  border-top: 5px solid #062e56;
}

.center {
  align-content: center;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.rc-slider-tooltip-hidden {
  display: block !important;
  z-index: 100000000 !important;
}

// cevra-logo 
.container-img {
  height: 200px;
  text-align: center;
}

.container-img img {
  height: 200px;
}

// mobile view
@media (max-width:767px) {
  .register-h1 {
    font-size: 25px;
  }
}


// fade effect
#cevra-logo {
  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s;
  /* Firefox < 16 */
  -ms-animation: fadein 2s;
  /* Internet Explorer */
  -o-animation: fadein 2s;
  /* Opera < 12.1 */
  animation: fadein 5s;
}

// cevra-device
#cevra-device {
  -webkit-animation: deviceFade 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: deviceFade 2s;
  /* Firefox < 16 */
  -ms-animation: deviceFade 2s;
  /* Internet Explorer */
  -o-animation: deviceFade 2s;
  /* Opera < 12.1 */
  animation: deviceFade 2s infinite;
  // animation-delay: 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  animation: fadein 2s;
}

@keyframes deviceFade {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.8;
  }
}

#L-text .auth-h2,
.auth-h5 {
  animation: faderight 2s;
  white-space: nowrap;
}

// left to right
@keyframes faderight {
  from {
    width: 0%;
    opacity: -1;
  }

  to {
    width: 100%;
    opacity: 1;
  }
}

#L-text hr {
  animation: faderight-hr 2s;
}

@keyframes faderight-hr {
  from {
    width: 0px;
    opacity: -1;
  }

  to {
    width: 480px;
    opacity: 1;
  }
}

// language selector
.select__control {
  border: none;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .lngSelect {
    width: 17% !important;
  }

  .h-img {
    width: 80px !important;
    height: 80px !important;
  }

  .col-9 h4 {
    font-size: 25px;
  }

  .header-dp {
    width: 50px;
  }

}

@media only screen and (min-device-width: 501px) and (max-device-width: 767px) {
  .lngSelect {
    width: 23% !important;
    right: 14pc !important;
  }

  .location-td {
    width: 80% !important;
  }
  .footer-font{
    font-size: 16px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width:500px) {
  .lngSelect {
    width: 35% !important;
    right: 8pc !important;
  }

  .h-img {
    width: 50px !important;
    height: 50px !important;
  }

  .col-9 h4 {
    font-size: 15px;
  }

  #drop-item {
    width: 50% !important;
  }

  .location-td {
    width: 100% !important;
  }
  .up-mr{
    margin-top: 20px;
  }
  .footer-font{
    font-size: 14px;
  }
}

.header-blue {
  background: linear-gradient(280deg, #ff8e54, #4680ff);
}

@media only screen and (max-width: 991px) {

  .pcoded-header>.collapse:not(.show),
  .pcoded-header .container>.collapse:not(.show) {
    background: none !important;
    box-shadow: none !important;
  }

  .pcoded-header.header-blue a {
    color: white !important;
  }
}

.errorMsg {
  color: red;
  float: left;
}


// news page img

#fileDrop {
  opacity: 0.0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0.0;

  /* older Safari browsers */
  -khtml-opacity: 0.0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#imgUP {
  height: 270px;
  border-radius: 8px;
}

#newsImg {
  height: 370px;
  border-radius: 10px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #imgUP {
    height: 200px;
  }

  #newsImg {
    height: 330px;
    border-radius: 10px;
  }
}

@media only screen and (min-device-width: 501px) and (max-device-width: 767px) {
  #newsImg {
    height: 240px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
  #imgUP {
    height: 150px;
  }

  #newsImg {
    height: 170px;
    border-radius: 10px;
  }
  .for-mobile{
    display: block !important;
  }
}

// @media only screen and (min-width: 992px){
//   .mobile-header{
//     display: none;
//   }
// }

th {
  background-color: #ecf3ff !important;
  // color: white !important;
}

// modal header 
.custom-header {
  background: linear-gradient(280deg, #ff8e54, #4680ff);
}

// card background
.card-back {
  background: linear-gradient(140deg, #fff2ed, #e4edff);
}

// jQuery table head
.f-size th {
  font-size: 14px !important;
}

// background colors
.a {
  background-color: #9d60d4;
}

.b {
  background-color: #60d4ce;
}

.f-size {
  font-size: 12px;
}

// icon height width table
.sm-ico {
  height: 35px !important;
  width: 34px !important;
  border: none
}

.sm-ico:hover {
  background-color: #3d3d3d;
}

@media only screen and (min-device-width: 1000px) {
  #ico {
    margin-left: 30px;
  }

  .border-div {
    border-right: 1px solid #e2e5e8;
  }
  .footer-font{
    font-size: 17px;
  }
}

// jQuery table location class
.location-td {
  white-space: pre-wrap !important;
  width: 30%;
}

.bold {
  font-weight: bolder;
}

// rating
.star-fill {
  color: #ffb036;
}

.star-blank {
  color: bisque;
}

.badge-orange {
  color: white;
  background-color: #ff8a46;
}

.badge-orange:hover {
  background-color: #ff9557;
}

// blink background
.blink-text {
  color: #000;
  font-weight: bold;
  animation: blinkText 1.5s infinite;
  animation-delay: 100ms;
  border-radius: 5px;
}

@keyframes blinkText {
  0% {
    color: #000;
    background-color: #fff;
  }

  50% {
    color: rgb(63, 63, 63);
    background-color: rgb(255, 233, 233);
  }

  100% {
    color: #000;
    background-color: #fff;
  }
}

.for-mobile{
  display: none;
}

@media only screen and (max-device-width: 700px) {
  .cevra-footer{
    display: none;
  }
}

.pointer{
  cursor: pointer;
}

.green {
  color: rgb(48, 216, 48);
}

.red {
  color: rgb(255, 62, 62);
}